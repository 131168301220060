.avatar {
    max-width: 50px;
    max-height: 50px;
    margin-right: 70px;
}
.styleH4-Admin {
    font-size: 24px;
    color: #8a8989;
    font-weight: bold;
}
.WellCome-admin {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    padding: 0;
}
