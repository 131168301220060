.label-styling {
    font-size: 18px;
    color: black;
    width: 200px;
    margin: 10px;
    font-weight: bold;
}
.input-styling {
    font-size: 18px;
    color: black;
    width: 500px;
    margin: 10px;
    font-weight: bold;
}
.styleSubmit-Admin {
    margin: 10px;
    float: right;
    font-weight: bold;
    font-size: 24px;
    height: 50px;
    width: 175px;
    color: black;
    background-color: #598392;
    border-radius: 9px;
}
.styleSubmit-Admin:hover {
    color: rgb(96, 117, 117);
    background-color: #3b535c;
}

.description-wrapper {
    display: flex; /* Sử dụng flexbox để các phần tử con nằm cùng một hàng */
    align-items: center; /* Căn chỉnh các phần tử con theo trục dọc */
}
