.input-Auth-NameInput {
    margin: 10px;
    width: 180px;
    height: 60px;
    background-color: #aec3b0 !important;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    margin-top: 0px;
}
.input-Auth-BirthDayInput {
    margin: 10px;
    width: 113px;
    height: 60px;
    background-color: #aec3b0 !important;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    margin-top: 0px;
}
.div-Auth-SignUp {
    border-radius: 10px;
    width: 445px;
    height: 835px;
    margin: 100px;
    padding: 50px;
    background-color: #598392;
}
