/* index.css */
.sidebar-profile {
    background-color: #aec3b0; /* Màu nền của sidebar */
    width: 300px; /* Độ rộng của sidebar */
    height: 100vh; /* Chiều cao của sidebar là chiều cao của viewport */
    padding: 20px; /* Khoảng cách padding bên trong sidebar */
}

.sidebar-item {
    margin-bottom: 20px; /* Khoảng cách giữa các mục sidebar */
}

.sidebar-button {
    font-size: 14px;
    font-weight: bold;
    width: 250px;
    border-radius: 5px;
    display: flex; /* Hiển thị các phần tử trong button theo chiều ngang */
    align-items: center; /* Căn giữa các phần tử theo chiều dọc */
    background-color: #598392; /* Màu nền của button */
    color: black; /* Màu chữ của button */
    padding: 10px; /* Khoảng cách padding bên trong button */
    border: #555; /* Loại bỏ viền của button */
    cursor: pointer; /* Hiển thị con trỏ khi rê chuột qua button */
    box-shadow: #598392;
}

.sidebar-button:hover {
    background-color: #555; /* Màu nền của button khi hover */
}

.sidebar-button span {
    margin-left: 10px; /* Khoảng cách giữa icon và văn bản */
}
