.displayAccountInput {
    width: 415px !important;
    height: 60px !important;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    background-color: #a4c3b2;
}
.iconPen {
    border: none !important;
    background-color: #598392;
    text-decoration: none;
    border-radius: 5px !important;

    height: 60px;
    width: 60px;
}
.icon {
    font-size: 26px;
    color: black;
}
.displayAccountInput2 {
    width: 475px !important;
    height: 60px !important;
    border-radius: 5px;
    font-size: 14px !important;
    padding: 10px;
    background-color: #a4c3b2 !important;
    font-weight: bold !important;
}
.avatar {
    width: 100px;
    height: 100px;
}
.accountSetting-Profile {
    margin-bottom: 50px;
}
