@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
    --primary-text-color: #01161e;
    --is-user-logged-in: 0;
}
* {
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
}
body {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    background-color: #01161e !important;
}
